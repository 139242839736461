



































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { BModal, BButton, BListGroup, BListGroupItem, VBModal } from 'bootstrap-vue'
import WebComponent from '@infinity/shared/decorators/WebComponent'
import { allFilters } from '@infinity/shared/web-components/filter'
import BaseFilter from '@infinity/shared/components/BaseFilter.vue'

@WebComponent('filter-bar')
@Component({
  components: {
    BModal,
    BButton,
    BListGroup,
    BListGroupItem
  },
  directives: {
    'b-modal': VBModal
  }
})
export default class FilterBarComponent extends Vue {
  @Prop()
  filterValue!: string

  @Prop()
  installationId!: number

  @Prop()
  installationTimeZone!: string

  allFilters: { [k: string]: typeof BaseFilter } = allFilters

  @Watch('filterValue')
  onValueChange () {
    this.$nextTick(
      () => {
        this.$forceUpdate()
      }
    )
  }

  created () {
    this.$nextTick(
      () => {
        this.$forceUpdate()
      }
    )
  }

  get availableFilters () {
    const { allFilters, applied }: { allFilters: { [k: string]: typeof BaseFilter }; applied: { [k: string]: string }} = this

    return Object.keys(allFilters)
      .filter((key: string) => {
        return Object.prototype.hasOwnProperty.apply(applied, [key]) === false
      })
      .reduce((filters: { [k: string]: typeof BaseFilter }, key: string) => {
        filters[key] = allFilters[key]

        return filters
      }, {})
  }

  get applied (): { [k: string]: string } {
    const defaults = {
      dateRange: 'last28days~f'
    }

    try {
      return Object.assign(
        defaults,
        JSON.parse(this.filterValue)
      )
    } catch {
      return defaults
    }
  }

  openFilter (filter: string) {
    this.$nextTick(
      () => {
        // @ts-ignore
        this.$refs[filter][0].vueComponent.show()
      }
    )
  }

  @Emit('apply')
  apply (event: CustomEvent) {
    const data = event.detail[0]

    this.$bvModal.hide('add-filter')
    this.$forceUpdate()

    const values = Object.assign({}, this.applied, data)

    for (const key in values) {
      if (values[key] === null) {
        delete values[key]
      }
    }

    return values
  }
}
