import tippy, { ReferenceElement } from 'tippy.js'
// eslint-disable-next-line import/no-unresolved
import { DirectiveBinding } from 'vue/types/options'

export default {
  inserted: function (el: Element, binding: DirectiveBinding) {
    const instance = tippy(el)
    instance.setProps({
      appendTo: (el.getRootNode() as Element) || document.body,
      arrow: true,
      content: binding.value,
      delay: [5, null],
      allowHTML: false,
      maxWidth: 350,
      // Tippy supports using data attributes to set properties. As we'll be setting this on the instance, we can
      // disable this which will also improve performance.
      ignoreAttributes: true
    })
  },
  unbind: function (el: ReferenceElement) {
    if (el._tippy) {
      el._tippy.destroy()
    }
  }
}
