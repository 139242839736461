






























import { Component, Emit } from 'vue-property-decorator'
import { BModal, BButton, VBModal, BFormRadioGroup } from 'bootstrap-vue'
import { FilterOperand } from '@infinity/shared/helpers/filter'
import BaseFilterComponent from '@infinity/shared/components/BaseFilter.vue'
import WebComponent from '@infinity/shared/decorators/WebComponent'

@WebComponent('filter-txr')
@Component({
  components: {
    BModal,
    BButton,
    BFormRadioGroup
  },
  directives: {
    'b-modal': VBModal
  }
})
export default class TxrFilterComponent extends BaseFilterComponent {
  static filterName = 'Call Reference'

  value = this.applied.value
  operand = this.applied.operand

  show () {
    this.$bvModal.show('txr-filter-modal')
  }

  @Emit('apply')
  removeFilter () {
    return {
      txr: null
    }
  }

  get operandOptions () {
    return [
      { text: 'Includes', value: FilterOperand.Includes },
      { text: 'Equals', value: FilterOperand.Equals }
    ]
  }

  get applied () {
    const { filterValue } = this
    if (typeof filterValue === 'string') {
      const [operand, value] = filterValue.split('~')

      return {
        operand,
        value
      }
    }

    return {
      operand: FilterOperand.Includes,
      value: ''
    }
  }

  get appliedOperandLabel () {
    const operand = this.operandOptions.find((option) => {
      return option.value === this.applied.operand
    })

    if (operand) {
      return operand.text
    }

    return ''
  }

  @Emit('apply')
  doApply () {
    this.$bvModal.hide('txr-filter-modal')

    return {
      txr: `${this.operand}~${this.value}~tr`
    }
  }

  static toApiParams (filterValue: string) {
    if (typeof filterValue === 'string') {
      const [operand, value] = filterValue.split('~')

      return {
        filters: [`txr-${operand}-value-${value}`]
      }
    }
  }
}
