import lodash from 'lodash'

const defaults = {
  dateRange: 'last28days~f'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toApiParams = (allFilters: any) => {
  return (values: object) => {
    values = Object.assign({}, defaults, values)

    return Object.entries(values).reduce((acc: { [k: string]: string | string[] }, [key, value]) => {
      if (lodash.has(allFilters, key)) {
        const filterValue = allFilters[key].toApiParams(value)

        if (lodash.has(filterValue, 'filters') && lodash.has(acc, 'filters')) {
          filterValue.filters = [...acc.filters, ...filterValue.filters]
        }

        Object.assign(acc, filterValue)
      }

      return acc
    }, {})
  }
}
