import Vue, { Component } from 'vue'
// @ts-ignore
import wrap from '@vue/web-component-wrapper'

Vue.config.ignoredElements = []

export default (name: string) => {
  Vue.config.ignoredElements.push(name)

  return (target: Component) => {
    Vue.nextTick(() => {
      if (window.customElements.get(name) === undefined) {
        // @ts-ignore
        window.customElements.define(name, wrap(Vue, target))
      }
    })
  }
}
