var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon icon--color-info",
      attrs: {
        "aria-hidden": "true",
        width: "64",
        height: "64",
        viewBox: "0 0 64 64"
      }
    },
    [
      _c("polygon", {
        attrs: {
          points:
            "50.3 6.1 50.3 1 44.5 1 44.5 6.1 19.5 6.1 19.5 1 13.7 1 13.7 6.1 4.5 6.1 4.5 17.2 59.5 17.2 59.5 6.1 "
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M4.5 22.6V63h55V22.6H4.5zM20.7 57.2H9.2V45.6h11.5V57.2zM20.7 40H9.2V28.5h11.5V40zM37.8 57.2H26.2V45.6h11.5V57.2zM37.8 40H26.2V28.5h11.5V40zM54.8 57.2H43.3V45.6h11.5V57.2zM54.8 40H43.3V28.5h11.5V40z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }