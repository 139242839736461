var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("div", { ref: "container", class: _vm.classNames }, [
        _c("div", { staticClass: "arrow", attrs: { "data-popper-arrow": "" } }),
        _c(
          "div",
          { staticClass: "popover-body" },
          [
            _vm._t("default", null, {
              popover: { show: _vm.showPopover, hide: _vm.hidePopover }
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }