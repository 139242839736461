









import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { createPopper, Instance, Placement } from '@popperjs/core'

export enum PopoverTrigger { Click = 'click', Hover = 'hover', Focus = 'focus'}
export enum PopoverEvent { Hide = 'infinity::popover::hide', Show = 'infinity::popover::show', Enable = 'infinity::popover::enable', Disable = 'infinity::popover::disable' }

@Component
export default class InfinityPopover extends Vue {
  @Prop({ required: true })
  target!: HTMLElement

  @Prop({ type: Boolean, default: false })
  show!: boolean

  @Prop({ default: '', type: String })
  customClass!: string

  @Prop({ default: PopoverTrigger.Click, type: String })
  triggers!: string

  @Prop({ default: 'bottom' })
  placement!: Placement

  enabled = true
  eventShow = false
  popper: Instance | null = null
  mutationObserver = new MutationObserver(this.onChildMutate)

  get classNames () {
    return `popover b-popover ${this.customClass} bs-popover-${this.currentPlacement}`
  }

  get currentPlacement () {
    if (this.popper === null) {
      return ''
    }

    return this.popper.state.placement
  }

  get isVisible () {
    // Always show if prop dictates to, otherwise only show when enabled and have been triggered by an event
    return this.show || (this.enabled && this.eventShow)
  }

  get rootElement () {
    return (this.$el.getRootNode() as HTMLElement)
  }

  get eventTriggers () {
    return this.triggers.split(' ')
  }

  created () {
    this.$root.$on(PopoverEvent.Enable, this.doEnablePopover)
    this.$root.$on(PopoverEvent.Disable, this.doDisablePopover)
    this.$root.$on(PopoverEvent.Show, this.doShowPopover)
    this.$root.$on(PopoverEvent.Hide, this.doHidePopover)

    if (this.target) {
      this.initTargetListeners()
    }
  }

  @Watch('target')
  onTargetChange (value: HTMLElement | undefined, oldValue: HTMLElement | undefined) {
    if (value && oldValue === undefined) {
      this.initTargetListeners()

      return
    }

    if (value === undefined && oldValue) {
      this.stopTargetListeners()

      return
    }

    this.stopTargetListeners()
    this.initTargetListeners()
  }

  @Watch('isVisible')
  onVisibilityChange (value: boolean, oldValue: boolean) {
    if (value && !oldValue && (this.popper === null)) {
      this.$nextTick(
        () => {
          if (this.enabled || this.show) {
            this.initPopoverInstance()
            this.onShow()
          }
        }
      )
    }

    if (!value && oldValue && this.popper) {
      this.popper.destroy()
      this.popper = null
      this.mutationObserver.disconnect()
      this.onHide()
    }
  }

  @Emit('shown')
  onShow () {
    if (this.target) {
      return this.target.getAttribute('id')
    }
  }

  @Emit('hidden')
  onHide () {
    if (this.target) {
      return this.target.getAttribute('id')
    }
  }

  @Emit('enabled')
  onEnable () {
    if (this.target) {
      return this.target.getAttribute('id')
    }
  }

  @Emit('disabled')
  onDisable () {
    if (this.target) {
      return this.target.getAttribute('id')
    }
  }

  onChildMutate () {
    if (this.popper) {
      this.popper.forceUpdate()
    }
  }

  doEnablePopover (id: string | undefined) {
    if (!id || id === this.target.getAttribute('id')) {
      this.enabled = true
      this.onEnable()
    }
  }

  doDisablePopover (id: string | undefined) {
    if (!id || id === this.target.getAttribute('id')) {
      this.enabled = false
      this.onDisable()
    }
  }

  doShowPopover (id: string | undefined) {
    if (!id || (this.target && id === this.target.getAttribute('id'))) {
      this.eventShow = true
    }
  }

  doHidePopover (id: string | undefined) {
    if (!id || (this.target && id === this.target.getAttribute('id'))) {
      this.eventShow = false
    }
  }

  beforeDestroy () {
    this.stopTargetListeners()

    this.$root.$off(PopoverEvent.Enable, this.doEnablePopover)
    this.$root.$off(PopoverEvent.Disable, this.doDisablePopover)
    this.$root.$off(PopoverEvent.Show, this.doShowPopover)
    this.$root.$off(PopoverEvent.Hide, this.doHidePopover)
  }

  initTargetListeners () {
    if (this.target) {
      if (this.eventTriggers.includes(PopoverTrigger.Hover)) {
        this.target.addEventListener('mouseenter', this.showPopover)
        this.target.addEventListener('mouseleave', this.hidePopover)
      }

      if (this.eventTriggers.includes(PopoverTrigger.Click)) {
        this.target.addEventListener('click', this.togglePopover)
      }

      if (this.eventTriggers.includes(PopoverTrigger.Focus)) {
        this.target.addEventListener('focusin', this.showPopover)
        this.target.addEventListener('focusout', this.hidePopover)
      }

      window.addEventListener('click', this.clickOutsideListener)
    }
  }

  stopTargetListeners () {
    if (this.target) {
      if (this.eventTriggers.includes(PopoverTrigger.Hover)) {
        this.target.removeEventListener('mouseenter', this.showPopover)
        this.target.removeEventListener('mouseleave', this.hidePopover)
      }

      if (this.eventTriggers.includes(PopoverTrigger.Click)) {
        this.target.removeEventListener('click', this.togglePopover)
      }

      if (this.eventTriggers.includes(PopoverTrigger.Focus)) {
        this.target.removeEventListener('focusin', this.showPopover)
        this.target.removeEventListener('focusout', this.hidePopover)
      }

      window.removeEventListener('click', this.clickOutsideListener)
    }
  }

  clickOutsideListener (event: MouseEvent) {
    // event.target will be the shadow dom, use composed path to get the actual element
    const eventTarget = event.composedPath()[0]

    if (eventTarget && this.$refs.container instanceof Element && this.target) {
      if (
        !this.target.contains(eventTarget as Node) && !this.$refs.container.contains(eventTarget as Node)
      ) {
        this.onClickOutside()
      }
    }
  }

  @Emit('clickOutside')
  onClickOutside () {
    if (this.target) {
      return this.target.getAttribute('id')
    }
  }

  togglePopover () {
    this.eventShow = this.eventShow === false
  }

  showPopover () {
    this.eventShow = true
  }

  hidePopover () {
    this.eventShow = false
  }

  initPopoverInstance () {
    if (this.$refs.container instanceof HTMLElement) {
      this.popper = createPopper(this.target, this.$refs.container, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          },
          {
            name: 'arrow',
            options: {
              padding: 5
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top']
            }
          },
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true // false by default
            }
          }
        ]
      })

      // Observe any changes inside the popover so we can update Popper
      this.mutationObserver.observe(this.$refs.container, {
        childList: true,
        subtree: true
      })
    }
  }
}
