var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "align-self-stretch" },
    [
      _vm.filterActive
        ? _c(
            "div",
            { staticClass: "d-flex filter shadow-sm bg-white rounded border" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.txr-filter-modal",
                      modifiers: { "txr-filter-modal": true }
                    }
                  ],
                  staticClass: "p-2",
                  attrs: { disabled: _vm.readOnly, button: "" }
                },
                [
                  _c("span", { staticClass: "title d-block" }, [
                    _vm._v("Call Reference")
                  ]),
                  _c("small", { staticClass: "d-block pt-2" }, [
                    _vm._v(
                      _vm._s(_vm.appliedOperandLabel) +
                        " " +
                        _vm._s(_vm.applied.value)
                    )
                  ])
                ]
              ),
              !_vm.readOnly
                ? _c(
                    "b-button",
                    { staticClass: "remove", on: { click: _vm.removeFilter } },
                    [_c("b-icon-x")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "txr-filter-modal",
            title: "Call Reference",
            static: ""
          },
          on: {
            ok: function($event) {
              return _vm.doApply()
            },
            cancel: function($event) {
              return _vm.$bvModal.hide("txr-filter-modal")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("b-form-radio-group", {
                staticClass: "w-50",
                attrs: {
                  options: _vm.operandOptions,
                  buttons: "",
                  "button-variant": "outline-primary"
                },
                model: {
                  value: _vm.operand,
                  callback: function($$v) {
                    _vm.operand = $$v
                  },
                  expression: "operand"
                }
              }),
              _c("b-input", {
                staticClass: "ml-1",
                attrs: { placeholder: "Enter a value" },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }