









import { Component, Prop, Vue } from 'vue-property-decorator'
import { BModal, BButton, VBModal } from 'bootstrap-vue'

@Component({
  components: {
    BModal,
    BButton
  },
  directives: {
    'b-modal': VBModal
  }
})
export default class BaseFilterComponent extends Vue {
  @Prop()
  filterValue!: string

  @Prop({ type: Boolean, default: false })
  filterActive!: boolean

  @Prop({ type: Boolean, default: false })
  readOnly!: boolean

  @Prop()
  installationId!: number

  @Prop()
  installationTimeZone!: string
}
