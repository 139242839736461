var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-1",
              modifiers: { "modal-1": true }
            }
          ]
        },
        [_vm._v("Open modal")]
      ),
      _c("b-modal", { attrs: { id: "modal-1", title: "Modal", static: "" } }, [
        _c("p", [_vm._v("Modal content")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }