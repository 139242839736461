








































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import {
  BButton,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BBadge,
  BIconTrashFill,
  BIconInfoCircle
} from 'bootstrap-vue'
import InfinityPopover from '@infinity/shared/components/layout/InfinityPopover.vue'
import { Keyword, KeywordClause } from '@infinity/shared/components/KeywordSpottingFilter.vue'

@Component({
  components: {
    BButton,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BBadge,
    BIconTrashFill,
    BIconInfoCircle,
    InfinityPopover
  }
})
export default class AddKeyword extends Vue {
  @Prop({ required: true })
  clause!: KeywordClause | null

  @Prop({ required: true })
  keywords!: Keyword[]

  @Prop({ default: 'success' })
  buttonVariant!: string

  @Prop({ default: '' })
  buttonClass!: string

  @Prop({ default: '' })
  size!: string

  @Prop({ default: false })
  pill!: boolean

  @Prop({ default: '' })
  keywordFilter!: string

  @Prop({ default: false })
  showDeleted!: boolean

  @Prop({ default: false })
  isLoadingKeywords!: boolean

  keywordFilterInput = ''
  showDeletedChecked = false
  popoverEnabled = false

  beforeMount () {
    this.keywordFilterInput = this.keywordFilter
    this.showDeletedChecked = this.showDeleted
  }

  enablePopover () {
    this.popoverEnabled = true
  }

  addKeyword (keyword: Keyword) {
    if (this.clause) {
      this.clause.addKeyword(keyword)
      return
    }

    this.addClause(keyword)
  }

  @Watch('keywordFilter')
  syncKeywordFilter () {
    this.keywordFilterInput = this.keywordFilter
  }

  @Watch('keywordFilterInput')
  updateKeywordFilter () {
    this.loadKeywords()
  }

  @Watch('showDeleted')
  syncShowDeleted () {
    this.showDeletedChecked = this.showDeleted
  }

  @Watch('showDeletedChecked')
  updateShowDeleted () {
    this.loadKeywords()
  }

  @Emit('loadKeywords')
  loadKeywords () {
    return {
      keywordFilter: this.keywordFilterInput,
      showDeleted: this.showDeletedChecked
    }
  }

  @Emit('addClause')
  addClause (keyword: Keyword) {
    return {
      keyword
    }
  }
}
