var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "align-self-stretch" },
    [
      _vm.filterActive
        ? _c(
            "div",
            { staticClass: "d-flex filter shadow-sm bg-white rounded border" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.keyword-filter-modal",
                      modifiers: { "keyword-filter-modal": true }
                    }
                  ],
                  staticClass: "p-2",
                  attrs: { button: "" }
                },
                [
                  _vm.isLoading
                    ? _c("b-spinner", {
                        attrs: { label: "Loading...", small: "" }
                      })
                    : [
                        _c("span", { staticClass: "title d-block" }, [
                          _vm._v("Spotted Keywords")
                        ]),
                        _c("small", { staticClass: "d-block pt-2" }, [
                          _vm._v(
                            "Spot: " +
                              _vm._s(_vm.applied.numOfKeywordsToSpot) +
                              ", Do not spot: " +
                              _vm._s(_vm.applied.numOfKeywordsToNotSpot)
                          )
                        ])
                      ]
                ],
                2
              ),
              !_vm.readOnly
                ? _c(
                    "b-button",
                    { staticClass: "remove", on: { click: _vm.removeFilter } },
                    [_c("b-icon-x")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "keyword-filter-modal",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header": "",
            "hide-footer": "",
            static: "",
            scrollable: ""
          },
          on: {
            show: function($event) {
              return _vm.getKeywords()
            }
          }
        },
        [
          _c("p", [_vm._v("Only show calls when:")]),
          _vm._l(_vm.clauses, function(clause, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  "d-flex p-3 mb-" +
                  (index === _vm.clauses.length - 1 ? "3" : "5") +
                  " border rounded flex-wrap"
              },
              [
                index > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "and-separator text-whale font-weight-bold"
                      },
                      [_vm._v(" AND ")]
                    )
                  : _vm._e(),
                !_vm.readOnly && index > 0
                  ? _c(
                      "b-button",
                      {
                        staticClass: "delete-clause",
                        attrs: { size: "sm", variant: "danger", pill: "" },
                        on: {
                          click: function($event) {
                            _vm.removeClause(clause)
                            _vm.getKeywords()
                          }
                        }
                      },
                      [_c("b-icon-trash")],
                      1
                    )
                  : _vm._e(),
                !_vm.readOnly
                  ? _c(
                      "b-button-group",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              variant: _vm.getClauseTypeButtonVariant(
                                clause.getType(),
                                _vm.KeywordClauseType.And
                              )
                            },
                            on: {
                              click: function($event) {
                                return clause.setType(_vm.KeywordClauseType.And)
                              }
                            }
                          },
                          [_vm._v("Spotted")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              variant: _vm.getClauseTypeButtonVariant(
                                clause.getType(),
                                _vm.KeywordClauseType.Not
                              ),
                              disabled:
                                !clause.isNotClause() && _vm.hasNotClause()
                            },
                            on: {
                              click: function($event) {
                                return clause.setType(_vm.KeywordClauseType.Not)
                              }
                            }
                          },
                          [_vm._v("Not spotted")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(clause.getKeywords(), function(keyword, kwIndex) {
                  return _c(
                    "div",
                    { key: keyword.getId(), staticClass: "d-block w-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex w-100 mb-2 align-items-center justify-content-between"
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-wrap" }, [
                            _c("small", { staticClass: "w-100 text-whale" }, [
                              _vm._v(_vm._s(keyword.getKeywordGroupName()))
                            ]),
                            _c("span", { staticClass: "w-100" }, [
                              _vm._v(_vm._s(keyword.getPhrase()))
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              clause.isAndClause()
                                ? _c("span", { staticClass: "mr-3" }, [
                                    _vm._v("is spotted")
                                  ])
                                : clause.isNotClause()
                                ? _c("span", { staticClass: "mr-3" }, [
                                    _vm._v("is not spotted")
                                  ])
                                : _vm._e(),
                              !_vm.readOnly
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-3 p-0",
                                      attrs: { variant: "outline", size: "sm" },
                                      on: {
                                        click: function($event) {
                                          clause.removeKeyword(keyword)
                                          _vm.getKeywords()
                                        }
                                      }
                                    },
                                    [_c("b-icon-x-circle-fill")],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.readOnly &&
                              kwIndex === clause.getNumOfKeywords() - 1
                                ? _c(
                                    "add-keyword",
                                    {
                                      attrs: {
                                        clause: clause,
                                        keywords: _vm.sortedKeywords,
                                        "button-variant": "success",
                                        "button-class": "ml-3",
                                        "keyword-filter": _vm.keywordFilter,
                                        "show-deleted": _vm.showDeleted,
                                        isLoadingKeywords: _vm.isLoadingKeywords
                                      },
                                      on: { loadKeywords: _vm.loadKeywords }
                                    },
                                    [_vm._v(" Or ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      kwIndex < clause.getKeywords().length - 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center mb-2 text-whale font-weight-bold"
                            },
                            [_vm._v("OR")]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                !_vm.readOnly && clause.getNumOfKeywords() === 0
                  ? _c(
                      "div",
                      { staticClass: "d-flex w-100 justify-content-center" },
                      [
                        _c(
                          "add-keyword",
                          {
                            attrs: {
                              clause: clause,
                              keywords: _vm.sortedKeywords,
                              "button-variant": "outline-primary",
                              size: "sm",
                              pill: true,
                              "keyword-filter": _vm.keywordFilter,
                              "show-deleted": _vm.showDeleted,
                              isLoadingKeywords: _vm.isLoadingKeywords
                            },
                            on: { loadKeywords: _vm.loadKeywords }
                          },
                          [
                            _c("b-icon-plus"),
                            _vm._v(" Add keyword "),
                            _c("b-icon-chevron-down", { staticClass: "ml-2" })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          }),
          !_vm.readOnly && _vm.clauses[0].getNumOfKeywords() > 0
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c(
                    "add-keyword",
                    {
                      attrs: {
                        clause: null,
                        keywords: _vm.sortedKeywords,
                        "button-variant": "success",
                        "keyword-filter": _vm.keywordFilter,
                        "show-deleted": _vm.showDeleted,
                        isLoadingKeywords: _vm.isLoadingKeywords
                      },
                      on: {
                        loadKeywords: _vm.loadKeywords,
                        addClause: _vm.addClause
                      }
                    },
                    [_vm._v(" And ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "link" },
                  on: {
                    click: function($event) {
                      _vm.$bvModal.hide("keyword-filter-modal")
                      _vm.keywordFilter = ""
                      _vm.hidePopovers()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.readOnly ? "Close" : "Cancel") + " ")]
              ),
              !_vm.readOnly
                ? _c(
                    "b-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { variant: "success" },
                      on: { click: _vm.doApply }
                    },
                    [_vm._v(" Apply ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }