




































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import {
  BButton,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BBadge,
  BIconTrashFill,
  BIconInfoCircle
} from 'bootstrap-vue'
import InfinityPopover from '@infinity/shared/components/layout/InfinityPopover.vue'
import { KeywordGroup, KeywordGroupClause } from './KeywordGroupFilter.vue'

@Component({
  components: {
    BButton,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BBadge,
    BIconTrashFill,
    BIconInfoCircle,
    InfinityPopover
  }
})
export default class AddKeywordGroup extends Vue {
  @Prop({ required: true })
  clause!: KeywordGroupClause | null

  @Prop({ required: true })
  keywordGroups!: KeywordGroup[]

  @Prop({ default: 'success' })
  buttonVariant!: string

  @Prop({ default: '' })
  buttonClass!: string

  @Prop({ default: '' })
  size!: string

  @Prop({ default: false })
  pill!: boolean

  @Prop({ default: '' })
  keywordGroupFilter!: string

  @Prop({ default: false })
  showDeleted!: boolean

  @Prop({ default: false })
  isLoadingKeywordGroups!: boolean

  keywordGroupFilterInput = ''
  showDeletedChecked = false
  popoverEnabled = false

  beforeMount () {
    this.keywordGroupFilterInput = this.keywordGroupFilter
    this.showDeletedChecked = this.showDeleted
  }

  enablePopover () {
    this.popoverEnabled = true
  }

  addKeywordGroup (keywordGroup: KeywordGroup) {
    if (this.clause) {
      this.clause.addKeywordGroup(keywordGroup)
      return
    }

    this.addClause(keywordGroup)
  }

  @Watch('keywordGroupFilter')
  syncKeywordGroupFilter () {
    this.keywordGroupFilterInput = this.keywordGroupFilter
  }

  @Watch('keywordGroupFilterInput')
  updateKeywordGroupFilter () {
    this.loadKeywordGroups()
  }

  @Watch('showDeleted')
  syncShowDeleted () {
    this.showDeletedChecked = this.showDeleted
  }

  @Watch('showDeletedChecked')
  updateShowDeleted () {
    this.loadKeywordGroups()
  }

  @Emit('loadKeywordGroups')
  loadKeywordGroups () {
    return {
      keywordGroupFilter: this.keywordGroupFilterInput,
      showDeleted: this.showDeletedChecked
    }
  }

  @Emit('addClause')
  addClause (keywordGroup: KeywordGroup) {
    return {
      keywordGroup
    }
  }
}
