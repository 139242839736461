var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          ref: "chooseKeywordGroup",
          class: _vm.buttonClass,
          attrs: { variant: _vm.buttonVariant, size: _vm.size, pill: _vm.pill },
          on: { mouseover: _vm.enablePopover }
        },
        [_vm._t("default")],
        2
      ),
      _vm.popoverEnabled
        ? _c("infinity-popover", {
            attrs: {
              target: _vm.$refs.chooseKeywordGroup,
              triggers: "click",
              placement: "bottom"
            },
            on: {
              shown: function($event) {
                return _vm.$refs.keywordGroupFilter.focus()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var popover = ref.popover
                    return [
                      _c(
                        "div",
                        { staticClass: "p-3" },
                        [
                          _c("b-input", {
                            ref: "keywordGroupFilter",
                            staticClass: "keyword-group-filter",
                            attrs: {
                              placeholder: "Filter by group...",
                              type: "text",
                              debounce: "500",
                              trim: ""
                            },
                            model: {
                              value: _vm.keywordGroupFilterInput,
                              callback: function($$v) {
                                _vm.keywordGroupFilterInput = $$v
                              },
                              expression: "keywordGroupFilterInput"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "ml-4 font-weight-bold",
                              attrs: { for: "show-deleted-checkbox" }
                            },
                            [_c("b-icon-trash-fill"), _vm._v(" Show Deleted")],
                            1
                          ),
                          _c("b-form-checkbox", {
                            staticClass: "ml-auto mr-2",
                            attrs: { id: "show-deleted-checkbox", switch: "" },
                            model: {
                              value: _vm.showDeletedChecked,
                              callback: function($$v) {
                                _vm.showDeletedChecked = $$v
                              },
                              expression: "showDeletedChecked"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.isLoadingKeywordGroups
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-center p-2"
                            },
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" }
                              })
                            ],
                            1
                          )
                        : _vm.keywordGroups.length > 0
                        ? _c(
                            "b-list-group",
                            { staticClass: "keyword-group-list" },
                            [
                              _vm._l(_vm.keywordGroups, function(keywordGroup) {
                                return _c(
                                  "b-list-group-item",
                                  {
                                    key: keywordGroup.getKeywordGroupId(),
                                    staticClass:
                                      "d-flex flex-wrap align-items-center font-weight-bold text-secondary",
                                    attrs: { button: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.addKeywordGroup(keywordGroup)
                                        _vm.keywordGroupFilterInput = ""
                                        _vm.loadKeywordGroups()
                                        popover.hide()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-wrap" },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "text-whale" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                keywordGroup.getKeywordGroupName()
                                              )
                                            )
                                          ]
                                        ),
                                        _vm.showDeletedChecked &&
                                        keywordGroup.isDeleted()
                                          ? _c(
                                              "small",
                                              { staticClass: "text-whale" },
                                              [_vm._v("(Deleted)")]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm.keywordGroups.length === 25
                                ? _c(
                                    "b-list-group-item",
                                    { staticClass: "px-2 text-center" },
                                    [
                                      _c("b-icon-info-circle", {
                                        attrs: { variant: "info" }
                                      }),
                                      _vm._v(" Only showing top 25 results... ")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        : _c(
                            "b-list-group",
                            { staticClass: "keyword-group-list" },
                            [
                              _c(
                                "b-list-group-item",
                                { staticClass: "px-2 text-center" },
                                [_vm._v(" No keyword groups found... ")]
                              )
                            ],
                            1
                          )
                    ]
                  }
                }
              ],
              null,
              false,
              594135400
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }