var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "d-flex w-100 mb-2 justify-content-start align-items-stretch"
      },
      [
        _c(
          "b-button",
          {
            ref: "choose",
            staticClass: "d-flex align-items-center shadow-sm rounded border",
            attrs: { id: "filter-trigger", variant: "success" }
          },
          [
            _c("b-icon-filter-circle-fill", { staticClass: "mr-2" }),
            _vm._v(" Filters "),
            _c("b-icon-chevron-down", { staticClass: "ml-2" })
          ],
          1
        ),
        _c("infinity-popover", {
          attrs: {
            target: _vm.$refs.choose,
            triggers: "click",
            placement: "bottom"
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var popover = ref.popover
                return [
                  _c(
                    "b-list-group",
                    [
                      Object.keys(_vm.availableFilters).length === 0
                        ? _c("b-list-group-item", { attrs: { disabled: "" } }, [
                            _vm._v(" No Filters Available ")
                          ])
                        : _vm._e(),
                      _vm._l(_vm.availableFilters, function(filter, key) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: key,
                            attrs: { button: "" },
                            on: {
                              click: function($event) {
                                popover.hide()
                                _vm.openFilter(key)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(filter.filterName) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-start align-items-stretch flex-wrap"
          },
          _vm._l(_vm.allFilters, function(filter, key) {
            return _c(
              "div",
              { key: key, staticClass: "d-inline-block" },
              [
                _c("filter-" + key.toLowerCase(), {
                  ref: key,
                  refInFor: true,
                  tag: "component",
                  attrs: {
                    "installation-id": _vm.installationId,
                    "installation-time-zone": _vm.installationTimeZone,
                    "filter-active": _vm.applied.hasOwnProperty(key),
                    "filter-value": _vm.applied[key]
                  },
                  on: { apply: _vm.apply }
                })
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }