import { render, staticRenderFns } from "./AddKeywordGroup.vue?vue&type=template&id=ed724852&"
import script from "./AddKeywordGroup.vue?vue&type=script&lang=ts&"
export * from "./AddKeywordGroup.vue?vue&type=script&lang=ts&"
var disposed = false

function injectStyles (context) {
  if (disposed) return
  var style0 = require("./AddKeywordGroup.vue?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/mfe-shared/mfe-shared/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed724852')) {
      api.createRecord('ed724852', component.options)
    } else {
      api.reload('ed724852', component.options)
    }
    module.hot.accept("./AddKeywordGroup.vue?vue&type=template&id=ed724852&", function () {
      api.rerender('ed724852', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AddKeywordGroup.vue"
export default component.exports