







































import { Component, Emit } from 'vue-property-decorator'
import { BModal, BButton, VBModal } from 'bootstrap-vue'
// @ts-ignore
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import BaseFilterComponent from '@infinity/shared/components/BaseFilter.vue'
import WebComponent from '@infinity/shared/decorators/WebComponent'
import Calendar from '@infinity/shared/components/layout/Calendar.vue'

type DateRange = {
  startDate: Date;
  endDate: Date;
}

interface NamedDateRange extends DateRange {
  name: string;
}

type NamedDateRanges = {
  [key: string]: NamedDateRange;
}

type DatePickerRanges = {
  [key: string]: Date[];
}

@WebComponent('filter-daterange')
@Component({
  components: {
    BModal,
    BButton,
    DateRangePicker,
    Calendar
  },
  directives: {
    'b-modal': VBModal
  },
  filters: {
    date (val: Date | string) {
      return val ? moment(val).format('DD-MM-YYYY') : ''
    }
  }
})
export default class DatePickerComponent extends BaseFilterComponent {
  static filterName = 'Date Range'

  currentDateRange: DateRange = {
    startDate: this.applied.startDate,
    endDate: this.applied.endDate
  }

  currentRangeName = this.applied.name

  show () {
    this.$bvModal.show('dateRangeModal')
  }

  get applied (): NamedDateRange {
    const { filterValue: value } = this

    if (typeof value === 'string') {
      const [identifier, ...values] = value.split('~').reverse()

      if (identifier === 'f') {
        const { name, startDate, endDate } = (this.constructor as typeof DatePickerComponent).ranges[values[0]]

        return {
          name,
          startDate,
          endDate
        }
      }

      return {
        name: 'Custom',
        endDate: moment.utc(values[0], 'YYYY-MM-DD').toDate(),
        startDate: moment.utc(values[1], 'YYYY-MM-DD').toDate()
      }
    }

    // default to last 28 days
    return {
      name: 'Last 28 Days',
      startDate: moment().subtract(28, 'days').toDate(),
      endDate: moment().subtract(1, 'day').toDate()
    }
  }

  locale = {
    daysOfWeek: moment.weekdaysMin(), // array of days
    monthNames: moment.monthsShort(), // array of month names
    firstDay: 1 // ISO first day of week
  }

  static get ranges (): NamedDateRanges {
    const today = moment().toDate()
    const yesterday = moment().subtract(1, 'day').toDate()
    const last7Days = moment().subtract(7, 'days').toDate()
    const last14Days = moment().subtract(14, 'days').toDate()
    const last28Days = moment().subtract(28, 'days').toDate()
    const last30Days = moment().subtract(30, 'days').toDate()
    const last31Days = moment().subtract(31, 'days').toDate()
    const last60Days = moment().subtract(60, 'days').toDate()
    const last90Days = moment().subtract(90, 'days').toDate()
    const thisWeekStart = moment().startOf('week').toDate()
    const thisWeekEnd = moment().endOf('week').toDate()
    const lastWeekStart = moment().subtract(1, 'week').startOf('week').toDate()
    const lastWeekEnd = moment().subtract(1, 'week').endOf('week').toDate()
    const thisMonthStart = moment().startOf('month').toDate()
    const thisMonthEnd = moment().endOf('month').toDate()
    const lastMonthStart = moment().subtract(1, 'month').startOf('month').toDate()
    const lastMonthEnd = moment().subtract(1, 'month').endOf('month').toDate()
    const thisYearStart = moment().startOf('year').toDate()
    const thisYearEnd = moment().endOf('year').toDate()
    const lastYearStart = moment().subtract(1, 'year').startOf('year').toDate()
    const lastYearEnd = moment().subtract(1, 'year').endOf('year').toDate()

    return {
      today: { name: 'Today', startDate: today, endDate: today },
      yesterday: { name: 'Yesterday', startDate: yesterday, endDate: yesterday },
      last7days: { name: 'Last 7 Days', startDate: last7Days, endDate: yesterday },
      last14days: { name: 'Last 14 Days', startDate: last14Days, endDate: yesterday },
      last28days: { name: 'Last 28 Days', startDate: last28Days, endDate: yesterday },
      last30days: { name: 'Last 30 Days', startDate: last30Days, endDate: yesterday },
      last31days: { name: 'Last 31 Days', startDate: last31Days, endDate: yesterday },
      last60days: { name: 'Last 60 Days', startDate: last60Days, endDate: yesterday },
      last90days: { name: 'Last 90 Days', startDate: last90Days, endDate: yesterday },
      thisweek: { name: 'This Week', startDate: thisWeekStart, endDate: thisWeekEnd },
      lastweek: { name: 'Last Week', startDate: lastWeekStart, endDate: lastWeekEnd },
      thismonth: { name: 'This Month', startDate: thisMonthStart, endDate: thisMonthEnd },
      lastmonth: { name: 'Last Month', startDate: lastMonthStart, endDate: lastMonthEnd },
      thisyear: { name: 'This Year', startDate: thisYearStart, endDate: thisYearEnd },
      lastyear: { name: 'Last Year', startDate: lastYearStart, endDate: lastYearEnd }
    }
  }

  static toApiParams (filterValue: string) {
    if (typeof filterValue === 'string') {
      const [identifier, ...values] = filterValue.split('~').reverse()

      if (identifier === 'f') {
        const { startDate, endDate } = this.ranges[values[0]]

        return {
          startDate: moment.utc(startDate).format('YYYY-MM-DD'),
          endDate: moment.utc(endDate).format('YYYY-MM-DD')
        }
      }

      return {
        endDate: values[0],
        startDate: values[1]
      }
    }
  }

  get datePickerRanges () {
    return Object.values((this.constructor as typeof DatePickerComponent).ranges).reduce((acc: DatePickerRanges, value) => {
      acc[value.name] = [value.startDate, value.endDate]

      return acc
    }, {})
  }

  updateCurrentRangeName (name: string) {
    this.currentRangeName = name
  }

  @Emit('apply')
  updateAppliedDateRange () {
    if (this.currentRangeName === 'custom') {
      return { dateRange: `${moment.utc(this.currentDateRange.startDate).format('YYYY-MM-DD')}~${moment.utc(this.currentDateRange.endDate).format('YYYY-MM-DD')}~c` }
    }

    const { ranges } = (this.constructor as typeof DatePickerComponent)

    const range = Object.keys(ranges).find((key) => {
      return ranges[key].name === this.currentRangeName
    })

    return { dateRange: `${range}~f` }
  }
}
