import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import TxrFilterComponent from '@infinity/shared/components/TxrFilter.vue'
import FilterBarComponent from '@infinity/shared/components/FilterBar.vue'
import DatePickerComponent from '@infinity/shared/components/DatePicker.vue'
import KeywordSpottingFilterComponent from '@infinity/shared/components/KeywordSpottingFilter.vue'
import KeywordGroupFilterComponent from '@infinity/shared/components/KeywordGroupFilter.vue'
import OperatorKeywordFilterComponent from '@infinity/shared/components/OperatorKeywordFilter.vue'
import ContactKeywordFilterComponent from '@infinity/shared/components/ContactKeywordFilter.vue'
// @ts-ignore
import VueTestAttribute from 'vue-test-attribute'
import InfinityPopover from '@infinity/shared/plugins/infinity-popover'
import { toApiParams } from '@infinity/shared/utils/filters'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTestAttribute)
Vue.use(InfinityPopover)

export const allFilters = {
  dateRange: DatePickerComponent,
  txr: TxrFilterComponent,
  keyword: KeywordSpottingFilterComponent,
  callKeywordGroups: KeywordGroupFilterComponent,
  callOperatorKeywords: OperatorKeywordFilterComponent,
  callContactKeywords: ContactKeywordFilterComponent
}

export const filterBar = FilterBarComponent

export const parseFiltersToApiParams = toApiParams(allFilters)
