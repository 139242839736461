import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { Api } from '@infinity/shared/helpers/api'
import Installation from '@infinity/shared/models/installation'

export enum StorageKey {
  AccessToken = 'accessToken',
  AuthUser = 'authUser',
  InstallationId = 'installationId',
  InstallationTimeZone = 'installationTimeZone',
  AuthMethod = 'authMethod',
  BasePath = 'basePath',
  EnabledFeatures = 'enabledFeatures'
}

export enum AuthMethod {
  Params = 'params',
  Login = 'login'
}

export class MfaError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'MfaError'
  }
}

export class AuthUtil {
  static installation = new Installation()

  static authenticateFromParams (accessToken: string, installationId: string, installationTimeZone: string) {
    localStorage.setItem(StorageKey.AccessToken, accessToken)
    localStorage.setItem(StorageKey.InstallationId, installationId)
    localStorage.setItem(StorageKey.InstallationTimeZone, installationTimeZone)
    localStorage.setItem(StorageKey.AuthMethod, AuthMethod.Params)
  }

  static async authenticate (username: string, password: string, verificationCode: string | null) {
    try {
      const requestData: { [key: string]: string } = { username, password }
      if (verificationCode !== null) {
        requestData.verificationCode = verificationCode
      }

      const response = await axios.post(`${Api.Hub}/security/anon/accesstoken`, requestData)

      if (response.data && response.data.accessToken) {
        localStorage.setItem(StorageKey.AccessToken, response.data.accessToken)
      }
    } catch (e) {
      const error = e as AxiosError
      if (error.response) {
        const { data: { userProfile: { mfaEnabled = '0' } = {} } = {} } = error.response as any // eslint-disable-line @typescript-eslint/no-explicit-any
        if (error.response.status === 403 && mfaEnabled === '1') {
          throw new MfaError(error.response.data as any) // eslint-disable-line @typescript-eslint/no-explicit-any
        }

        throw new Error(error.response.data as any) // eslint-disable-line @typescript-eslint/no-explicit-any
      }

      throw e
    }
  }

  static get authenticatedHeaders (): { [k: string]: string | null } | null {
    if (this.isAuthenticated === false) {
      return null
    }

    return {
      'x-auth-token': this.accessToken,
      Authorization: `Token ${this.accessToken}`
    }
  }

  static async logout () {
    try {
      await axios.delete(`${Api.Hub}/user/logout`, {
        headers: AuthUtil.authenticatedHeaders
      } as AxiosRequestConfig)

      AuthUtil.invalidateSession()
    } catch (e) {
      const error = e as AxiosError
      if (error.response) {
        throw new Error(error.response.data as any) // eslint-disable-line @typescript-eslint/no-explicit-any
      }

      throw e
    }
  }

  static invalidateSession () {
    localStorage.removeItem(StorageKey.AccessToken)
  }

  static get isAuthenticated (): boolean {
    return this.accessToken !== null
  }

  static get accessToken (): string | null {
    return localStorage.getItem(StorageKey.AccessToken)
  }

  static get installationId (): string | null {
    return AuthUtil.installation.getId() || localStorage.getItem(StorageKey.InstallationId)
  }

  static setInstallationId (id: string | null) {
    if (id) {
      localStorage.setItem(StorageKey.InstallationId, id)
    }
  }

  static get installationTimeZone (): string | null {
    return AuthUtil.installation.getTimeZone() || localStorage.getItem(StorageKey.InstallationTimeZone)
  }

  static setInstallationTimeZone (timeZone: string | null) {
    if (timeZone) {
      localStorage.setItem(StorageKey.InstallationTimeZone, timeZone)
    }
  }

  static get enabledFeatures (): Array<string> | null {
    const enabledFeaturesStr = localStorage.getItem(StorageKey.EnabledFeatures)
    return enabledFeaturesStr?.split(',') || null
  }

  static setEnabledFeatures (enabledFeatures: Array<string> | null) {
    if (enabledFeatures) {
      const enabledFeaturesStr = enabledFeatures.join(',')
      localStorage.setItem(StorageKey.EnabledFeatures, enabledFeaturesStr)
    }
  }

  static get authMethod () {
    return localStorage.getItem(StorageKey.AuthMethod)
  }

  static get basePath () {
    return localStorage.getItem(StorageKey.BasePath)
  }
}
