var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "align-self-stretch" },
    [
      _vm.filterActive
        ? _c(
            "div",
            { staticClass: "d-flex filter shadow-sm bg-white rounded border" },
            [
              _c(
                "div",
                { staticClass: "align-self-center p-2" },
                [_c("Calendar")],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.dateRangeModal",
                      modifiers: { dateRangeModal: true }
                    }
                  ],
                  staticClass: "p-2",
                  attrs: { disabled: _vm.readOnly, button: "" }
                },
                [
                  _c("span", { staticClass: "title d-block" }, [
                    _vm._v("Date Range")
                  ]),
                  _c("small", { staticClass: "d-block" }, [
                    _vm._v(_vm._s(_vm.applied.name))
                  ]),
                  _c("small", { staticClass: "d-block" }, [
                    _vm._v(
                      _vm._s(_vm._f("date")(_vm.applied.startDate)) +
                        " to " +
                        _vm._s(_vm._f("date")(_vm.applied.endDate))
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "dateRangeModal",
            title: "Date Range",
            size: "lg",
            static: ""
          },
          on: {
            ok: function($event) {
              return _vm.updateAppliedDateRange()
            }
          }
        },
        [
          _c("date-range-picker", {
            ref: "picker",
            attrs: {
              opens: "inline",
              "locale-data": _vm.locale,
              "auto-apply": true,
              ranges: _vm.datePickerRanges
            },
            on: {
              update: function($event) {
                return _vm.updateCurrentRangeName("custom")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function(picker) {
                  return [
                    _c("span", { staticClass: "fromDate" }, [
                      _vm._v(
                        "From Date: " + _vm._s(_vm._f("date")(picker.startDate))
                      )
                    ]),
                    _c("span", { staticClass: "toDate" }, [
                      _vm._v(
                        "To Date: " + _vm._s(_vm._f("date")(picker.endDate))
                      )
                    ])
                  ]
                }
              },
              {
                key: "ranges",
                fn: function(ranges) {
                  return [
                    _c("div", { staticClass: "ranges" }, [
                      _c(
                        "ul",
                        _vm._l(ranges.ranges, function(range, name) {
                          return _c(
                            "li",
                            {
                              key: name,
                              on: {
                                click: function($event) {
                                  ranges.clickRange(range)
                                  _vm.updateCurrentRangeName(name)
                                }
                              }
                            },
                            [
                              _c("b", [_vm._v(_vm._s(name))]),
                              _c("br"),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  _vm._s(_vm._f("date")(range[0])) +
                                    " - " +
                                    _vm._s(_vm._f("date")(range[1]))
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.currentDateRange,
              callback: function($$v) {
                _vm.currentDateRange = $$v
              },
              expression: "currentDateRange"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }